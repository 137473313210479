import type { Plugin } from 'vue'
import { defaultMapLibreConfig } from './maplibreUtils'
import type { MapLibreConfig } from './maplibreUtils'

export default {
  install: (app, options: MapLibreConfig) => {
    const maplibreConfig = {
      ...defaultMapLibreConfig,
      ...options,
    }

    app.config.globalProperties.maplibre = maplibreConfig
    app.provide('maplibre', maplibreConfig)
  },
} as Plugin
