import { createApp, h, provide } from 'vue'
import { ApolloClients } from '@vue/apollo-composable'

import 'swagger-ui/dist/swagger-ui.css'
import 'v-calendar/dist/style.css'
import '@/assets/style/index.css'

import { createHead } from '@unhead/vue'
import type { AppModule } from './types'
import App from '@/App.vue'

import { clients } from '@/modules/apollo'

const head = createHead()

export const app = createApp({
  setup() {
    provide(ApolloClients, clients)
  },
  render: () => h(App),
})

// install all modules under `modules/`
Object.values(import.meta.glob<{ default: AppModule }>('./modules/*.ts', { eager: true })).forEach(
  (module) => {
    app.use(module?.default)
  },
)

app.use(head)
app.mount('#app')
