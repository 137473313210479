import type { AppModule } from '@/types'

export default {
  install: (app) => {
    const hotjarId = import.meta.env.VITE_HOTJAR_SITE_ID

    const isEnabled = ref(false)
    const isLoaded = ref(false)

    const hotjarInstance = computed(() => (get(isLoaded) && window.hj) || null)

    const hotjar = reactive({
      enabled: isEnabled,
      loaded: isLoaded,
      init: () => initHotjar(hotjarId),
      event: (...args: any[]) => get(hotjarInstance)?.('event', ...args),
      identify: (...args: any[]) => get(hotjarInstance)?.('identify', ...args),
      stateChange: (...args: any[]) => get(hotjarInstance)?.('stateChange', ...args),
    })

    const a: HTMLHeadElement | undefined = undefined
    const r: HTMLScriptElement | undefined = undefined

    function initHotjar(hotjarId: string, snippetVersion = 6) {
      ;(function (h, o, t, j, a, r) {
        h.hj
          = h.hj
          || function (...args: any[]) {
            ;(h.hj.q = h.hj.q || []).push(args)
          }
        h._hjSettings = {
          hjid: hotjarId, // Hotjar ID
          hjsv: snippetVersion, // Hotjar Snippet Version
        }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = true
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)

        r.onload = async () => {
          await nextTick()
          set(isLoaded, true)
        }

        set(isEnabled, true)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', a, r)
    }

    if (hotjarId) {
      app.config.globalProperties.hotjar = hotjar
      app.provide('hotjar', hotjar)
    }
  },
} as AppModule
