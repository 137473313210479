import * as Sentry from '@sentry/vue'
import type { AppModule } from '@/types'

export default {
  install: (app) => {
    if (import.meta.env.VITE_SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        release: __APP_VERSION__,
        beforeSend(event, hint) {
          // Filter out 401 errors from apollo ?
          const error = hint.originalException as { networkError?: { statusCode: number } }

          if (error.networkError?.statusCode === 401) {
            return null
          }

          return event
        },
      })
    }
  },
} as AppModule
