import { createGtm } from '@gtm-support/vue-gtm'
import type { AppModule } from '@/types'
import router from '@/modules/router'

export default {
  install: (app) => {
    if (import.meta.env.VITE_GA_KEY) {
      app.use(
        createGtm({
          id: import.meta.env.VITE_GA_KEY,
          // queryParams: {
          //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
          //   gtm_preview: 'env-4',
          //   gtm_cookies_win: 'x',
          // },
          defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
          compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
          // nonce: '2726c7f26c', // Will add `nonce` to the script tag
          enabled: false,
          debug: IS_DEV,
          loadScript: true,
          vueRouter: router,
          // ignoredViews: ['Home'],
          trackOnNextTick: false,
        }),
      )
    }
  },
} as AppModule
