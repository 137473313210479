import { ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js/pure'
import type { Stripe } from '@stripe/stripe-js'
import type { AppModule } from '@/types'

export default {
  install: async (app, options) => {
    loadStripe.setLoadParameters({
      advancedFraudSignals: import.meta.env.PROD,
      ...options,
    })

    const stripeLoaded = ref(false)
    const stripe = ref<Stripe | null>(null)
    loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx').then((stripeScript) => {
      stripe.value = stripeScript
      stripeLoaded.value = true
    })

    app.provide(PROVIDE_STRIPE, stripe)
    app.provide(PROVIDE_STRIPE_LOADED, stripeLoaded)
  },
} as AppModule
